/// <reference path="./index.d.ts" />

import { graphql } from "gatsby";
import React, { useEffect } from "react";
import InstagramFeed from "react-ig-feed";
import "react-ig-feed/dist/index.css";
import { Categories } from "../components/Categories";
import FrontPage from "../components/frontpage";
import Hero from "../components/hero";
import Navigation from "../components/navigation";
import Title from "../components/title";
import { VoucherCategories } from "../components/VoucherCategories";
import "../css/index.css";
import Tie from "../images/tie.svg";
import Modal from "../components/modal";
import { getZipCode } from "../utils";
import useCart from "../context/cart.context";
import Container from "../components/container";
import Footer from "../components/footer";
import { useIsMobile } from "../hooks";
import RedeemModal from "../components/redeem";
<script src="https://html2canvas.hertzen.com/dist/html2canvas.js"></script>;

const IndexPage = ({ data }) => {
  const homeContent = data.allWp?.nodes[0].acfOptionsHomepage.bbThemeHomepage;
  // console.log(Object.keys(homeContent));
  const [openModal, setOpenModal] = React.useState(false);
  const [openRedeem, setOpenRedeem] = React.useState(false);
  const [redirect, setRedirect] = React.useState("");
  const [selectedSlug, setSelectedSlug] = React.useState(null);
  const queries = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : {}
  );
  const isMobile = useIsMobile();
  const ref = React.createRef<HTMLButtonElement>();
  const redeemCancelRef = React.createRef<HTMLButtonElement>();
  const { zipCode } = useCart();
  const homeDescription = homeContent.description;
  const homeBg = homeContent.coverBg ? homeContent.coverBg.guid : "";
  const orderParallaxBg = homeContent.orderParallaxBg
    ? homeContent.orderParallaxBg.guid
    : "";
  const voucherParallaxBg = homeContent.voucherParallaxBg
    ? homeContent.voucherParallaxBg.guid
    : "";
  const instagramParallaxBg = homeContent.instagramParallaxBg
    ? homeContent.instagramParallaxBg.sourceUrl
    : "";

  useEffect(() => {
    // console.log("code", queries.get("gutschein"));
    if (queries.get("gutschein")) {
      setOpenRedeem(true);
    }
  }, []);
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          backgroundImage: `url(${homeBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <FrontPage description={homeDescription} />
        <Navigation noHero={true} hideOnStart={!isMobile} />
        <Hero src={orderParallaxBg} />
      </div>
      <div
        id="brunch-delivery"
        onClick={() =>
          typeof window !== undefined && !getZipCode
            ? setOpenModal(true)
            : setOpenModal(false)
        }
      >
        <Categories
          desc={homeContent.orderDescription}
          data={data.boxes}
          setSelectedSlug={setSelectedSlug}
        />
      </div>
      <Hero src={voucherParallaxBg} />
      <div
        id="brunch-vouchers"
        onClick={() =>
          typeof window !== undefined && !getZipCode && !zipCode
            ? setOpenModal(true)
            : setOpenModal(false)
        }
      >
        <VoucherCategories
          data={data.vouchers}
          setRedirect={setSelectedSlug}
          contentData={homeContent}
        >
          <Title
            title={data.wcProductsCategories.acf.schenken_title}
            breadcrumb="schenken"
            subtitle={homeContent.voucherDescription}
          />
        </VoucherCategories>
      </div>
      <Hero src={instagramParallaxBg} />
      <div className="pt-20 text-center mt-10">
        <Tie className="mx-auto mb-6 h-7" src="./tie.svg" alt="" />
        <p className="mt-6 mb-20 font-serif text-4xl font-normal text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl">
          Neues vom BrunchButler
        </p>
      </div>
      <div className="custom-ig w-[63%] m-auto">
        <InstagramFeed reels={false} counter={9} token={homeContent.instagramToken ?? ""} />
      </div>
      <div className="pb-20 text-center">
        <p className="px-8 mx-auto pt-[80px] text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
          Folgen Sie uns auf{" "}
          <a
            href="https://instagram.com/brunchbutler"
            target="_blank"
            rel="noreferrer"
          >
            @brunchbutler
          </a>
        </p>
      </div>
      <Footer />
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        pCancelButtonRef={ref}
        // redirect={redirect}
        selectedSlug={selectedSlug}
      />
      <RedeemModal
        pCancelButtonRef={redeemCancelRef}
        onClose={() => setOpenRedeem(false)}
        defaultCode={queries.get("gutschein") ? queries.get("gutschein") : ""}
        open={openRedeem}
      />
    </>
  );
};

export const query = graphql`
  query HomePageQuery {
    allWp {
      nodes {
        acfOptionsHomepage {
          bbThemeHomepage {
            description
            coverBg {
              guid
            }
            orderParallaxBg {
              guid
            }
            voucherParallaxBg {
              guid
            }
            instagramParallaxBg {
              sourceUrl
            }
            voucherDescription
            voucherDescription2
            instagramToken
            orderDescription
          }
        }
      }
    }
    wcProductsCategories(slug: { eq: "box" }) {
      acf {
        hero_image_localFile {
          url
        }
        schenken_title
        schenken_description
      }
    }
    boxes: wpProductCategory(slug: { eq: "box" }) {
      id
      name
      slug
      description
      products {
        nodes {
          ... on WpBundleProduct {
            id
            name
            slug
            description
            price
            image {
              id
              sourceUrl
            }
            metaData {
              key
              value
            }
            attributes {
              nodes {
                name
                options
              }
            }
          }
        }
      }
    }
    vouchers: wpProductCategory(slug: { eq: "vouchers" }) {
      id
      name
      slug
      description
      products {
        nodes {
          ... on WpBundleProduct {
            id
            name
            slug
            description
            price
            image {
              id
              sourceUrl
            }
            metaData {
              key
              value
            }
            attributes {
              nodes {
                name
                options
              }
            }
          }
        }
      }
    }
  }
`;
// console.log("qu", query);
export default IndexPage;
