import React, { createRef, useState } from "react";

import Logo from "../images/logo.svg";

import { scroller } from "react-scroll";
import RedeemModal from "./redeem";
import CheckModal from "./modal";
import { getZipCode } from "../utils";
import useCart from "../context/cart.context";

const FrontPage: React.FunctionComponent<{
    description: string;
}> = ({ description }) => {
    const [redeemIsOpen, setRedeemIsOpen] = useState<boolean>(false);
    const [checkIsOpen, setCheckIsOpen] = useState<boolean>(false);
    const { zipCode } = useCart()
    const ref = createRef<HTMLButtonElement>();
    const ref2 = createRef<HTMLButtonElement>();

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="max-w-4xl px-4 mx-auto sm:px-12">
                <div className="pb-12 text-center sm:pb-16 2xl:pb-24 pt-40 ">
                    <Logo className={"2xl:h-56 md:h-48 sm:h-42 h-36 mx-auto"} />
                </div>

                <main dangerouslySetInnerHTML={{__html: description}} className="pb-12 text-center text-black md:text-xl sm:pb-16"></main>
                <div className="mb-24 sm:mb-0">
                    <div className="grid grid-cols-1 sm:grid-cols-3">
                        <div
                            onClick={() => {
                                scroller.scrollTo("brunch-delivery", {
                                    duration: 800,
                                    offset: -50,
                                    delay: 0,
                                    smooth: "easeInOutQuart",
                                });

                                if (!zipCode) {
                                    setCheckIsOpen(true);
                                }
                            }}
                            className={"cursor-pointer"}
                        >
                            <img src='https://ru06wamdpv.preview.infomaniak.website/wp-content/uploads/2022/03/BrunchButler-Brunch-Box-William.png' className="h-28 w-32 mb-4 mx-auto" alt="Brunch bestellen" />
                            <h3 className="mt-1 mb-6 font-serif text-xl leading-tight text-center text-black xl:text-2xl">
                                Brunch
                                <br />
                                bestellen
                            </h3>
                        </div>
                        <div
                            onClick={() => {
                                scroller.scrollTo("brunch-vouchers", {
                                    duration: 800,
                                    offset: -50,
                                    delay: 0,
                                    smooth: "easeInOutQuart",
                                });

                                if (typeof window !== "undefined") {
                                    if (!(window as any)?.localStorage.getItem("brunchbutler_zipCode")) {
                                        setCheckIsOpen(true);
                                    }
                                }
                            }}
                            className={"cursor-pointer"}
                        >
                            <img src={"/giftbox.png"} className="h-32 mx-auto" alt="Gutschein schenken" />
                            <h3 className="mt-1 mb-6 font-serif text-xl leading-tight text-center text-black xl:text-2xl">
                            Gutschein
                                <br />
                                schenken
                            </h3>
                        </div>
                        <div
                            onClick={() => {
                                setRedeemIsOpen(true);
                            }}
                            className={"cursor-pointer"}
                        >
                            <img src={"/coupon.jpg"} className="h-32 mx-auto" alt="Gutschein einlösen" />
                            <h3 className="mt-1 mb-6 font-serif text-xl leading-tight text-center text-black xl:text-2xl">
                                Gutschein
                                <br />
                                einlösen
                            </h3>

                            <RedeemModal open={redeemIsOpen} onClose={() => setRedeemIsOpen(false)} pCancelButtonRef={ref} />
                            <CheckModal open={checkIsOpen} onClose={() => setCheckIsOpen(false)} pCancelButtonRef={ref2} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FrontPage;
