import React, { useState } from "react";
import { TProduct } from "../types/types";
import { formatMoney, getZipCode } from "../utils";
import Title from "./title";
import { navigate } from "gatsby";
import useCart from "../context/cart.context";

export const VoucherCategories = ({ data, children = null, setRedirect = (arg) => console.log(false), contentData }) => {
  const { name, name: slug, description } = data;
  const { zipCode } = useCart()
  const sortedProducts = data.products.nodes.sort((a, b) => {
    if (a.name < b.name) {
      return 1;
    }
    if (a.name > b.name) {
      return -1;
    }
    return 0;
  })
  return (
    <div className="mb-20 pt-20 mt-10 mx-auto max-w-7xl">
      {children ? (
        children
      ) : (
        <Title title={name} breadcrumb={slug} subtitle={contentData.voucherDescription} />
      )}
      <div className="pt-20 space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8 gap-y-8">
        {sortedProducts
          .map(
            (
              product: TProduct & { suitableFor: boolean },
              productIndex: number
            ) => {
              const metaDataSubtitle = product.metaData.find(
                (item) => item.key === "subtitle"
              );
              const metaDataSubtitleValue =
                metaDataSubtitle && metaDataSubtitle.value;
              return (
                <div
                  key={productIndex}
                  className="relative flex flex-col py-8 pb-16 mx-8 mb-8 cursor-pointer lg:mx-0 lg:mb-0 bg-olivelight"
                >
                  <img
                    src="/ribbon.png"
                    alt=""
                    loading="lazy"
                    width={85}
                    className="absolute top-0 left-0"
                  />
                  <button onClick={() => {
                    setRedirect(`/gutscheine/${product.slug}`)
                    if(zipCode){
                      navigate(`/gutscheine/${product.slug}`);
                    }
                  }}>
                    <div className="flex-1 ">
                      <h3 className="w-64 mx-auto mt-4 font-serif text-3xl font-normal text-center text-[#707a4e]">
                        Gutschein {product.name.replace("Gutschein ", "")}
                      </h3>

                      <div className="relative mt-4 mb-8">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full " />
                        </div>
                      </div>
                    </div>

                    <div className="relative mt-4">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full" />
                      </div>
                    </div>

                    <span className="block w-full px-6 py-1 mt-6 text-[16px] text-center text-[#707a4e] font-bold border border-transparent">
                      {formatMoney(product.price)}
                    </span>

                    <p className="text-center text-oliveStrong text-[16px] leading-none">
                      {metaDataSubtitleValue}
                    </p>
                  </button>
                  <img
                    src="/ribbon.png"
                    alt=""
                    loading="lazy"
                    width={85}
                    className="absolute bottom-0 right-0 rotate-180"
                  />
                </div>
              );
            }
          )
          .reverse()}
      </div>
      <div className="pt-[80px]">
        <p dangerouslySetInnerHTML={{ __html: contentData.voucherDescription2 }} className="text-center mx-auto text-xl font-light leading-relaxed text-black max-w-7xl">
          {/*{contentData.voucherDescription2}*/}
          {/*Wählen Sie hier Ihren Brunch-Box-Gutschein - ich biete Ihnen drei*/}
          {/*verschiedene Brunch-Boxen zu verschiedenen grossen Preisen. Während*/}
          {/*des Bestellvorgangs können Sie Zusätze, wie z.B. meine Supplements,*/}
          {/*Champagner, NZZ am Sonntag etc. hinzufügen*/}
          {/*Link temporary removed by task*/}
          {}
          {/*<span className="text-[#919976]">*/}
          {/*  <a href="/brunch-schenken/">Zur Gutscheine-Übersicht...</a>*/}
          {/*</span>*/}
        </p>
      </div>
    </div>
  );
};
