import React from "react";

const Hero: React.FunctionComponent<{
    srcSet?: string;
    sizes?: string;
    src: string;
    alt?: string;
}> = ({ src }) => {
    return (
        <>
            <div
                className="w-full h-64 bg-center bg-no-repeat bg-cover md:bg-fixed bg-hero sm:h-104"
                style={{
                    backgroundImage: `url('${src}')`,
                }}
            ></div>
        </>
    );
};

export default Hero;
